@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'MBCorpoSText-Regular-Web', Arial;
$boldFontFamily: 'MBCorpoSText-Bold-Web', Arial;
$boldFontWeight: 700;
$titleFontFamily: 'MBCorpoATitle-Regular-Web', Arial;
$titleFontWeight: 400;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #000000;
$linkColor: #00adef;
$labelColor: #aaaaaa;
$formColor: #333333;
$utilColor: #00adef;
$priceColor: #666666;
$lightColor: #666666;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #16181f;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #00ADEF;
$warningColor: #333333;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #666666;
$colorBtnAlpha: #fff;
$bgBtnBeta: #00adef;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #737373;
$colorBtnOutlineBeta: #00adef;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 5px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #686869;
$calculatorMainColor: #ffffff;
$calculatorButton: #ff1b43;

$garageBackground: #ffffff;
$garageTextColor: #333333;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #333333;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #666666;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #666666;
$progressBarCloseButtonBG: #666666 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #333333;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #666666;
$paymentCalculatorTermColor: #ffffff;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__mercedes-benz{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__mercedes-benz{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .widget-login .dropdown-content{
            .is-light{
                color: $colorTopbarLogin !important;
            }
        }
        .widget-login-popupElement .SignInForm .ModalHeader .sub-text{
            color: #000 !important;
        }
        .tile-tag{
            background: #00adef!important;
        }

        .listing-used{
            background-color: #ffffff;
        }

        .listing-tile-all-price-info .price{
            color: #00adef!important;
        }

        .listing-tile-note, .listing-tile-note .vehicle-note{
            background: #ffffff;
        }

        .listing-tile-all-price-retails{
            color: #000000;
        }
        .listing-tile .listing-tile-image .car-name{
            font-family: $titleFontFamily;
            font-weight: $titleFontWeight;
            font-size: 25px;
            &.is-bold{
                font-family: $titleFontFamily;
                font-weight: $titleFontWeight;
            }
        }
        .sr-paymentCalculator-dropdown .Select-control, .sr-paymentCalculator-dropdown .is-focused .Select-control, .sr-paymentCalculator-dropdown .Select-option{
            background: #333333!important;
        }

         .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount sup,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate sup{
            vertical-align: text-bottom !important;
            font-size: 12px;
        }
        .vdp-used-banner .paymentCalculator__interests--amount sup,
        .vdp-used-banner .paymentCalculator__payment sup{
            vertical-align: text-bottom !important;
        }
        .vehicleBanner-personsViewed .amount.is-price{
            color:#00ADEF;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #00adef;
        }

        .message-handler.warning{
            background: #333333;
        }

        .section-catalog-header .catalog-warning{
            background: #00adef;
        }
        .section-showroom-header__model-listing-item.info-vehicle span.info-vehicle__make{
            margin-right: 4px;
        }
        .sr-modal__wrapper div.style-specs__modal-close-bloc{
            overflow: unset;
        }
        .style-color__button.selected{
            border-color: $utilColor;
        }
        .sr-sidebarsummary{
            font-family: Arial, Helvetica, sans-serif;
            .sr-title{
                font-family: Arial, Helvetica, sans-serif;
                font-size: 12px;
                font-weight: 800;
                color: #111111;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            .sr-text-regular{
                color: #555555;
                font-size: 12px;
            }
            .sr-text-bold{
                 @extend .sr-text-regular;
                 font-family: Arial, Helvetica, sans-serif;
                 font-weight: 700;
                color: #111111
            }
            .sr-text-thin{
                font-size: 11px;
                color: #aaaaaa;
            }
            .sr-important-price{
                font-size: 18px;
                color: #333333;
            }
            .vehicle{
                &__totalPayment .sr-important-price{
                    color: $priceColor;
                }
                &-title{
                    font-family: $fontFamily;
                }
            }
            .SidebarSummary{
                &__listingAmount{
                    color: #333333;
                }
            }
        }
        .listing-used-car-search [type=color],.listing-used-car-search [type=date],.listing-used-car-search [type=datetime-local],
        .listing-used-car-search [type=datetime],.listing-used-car-search [type=email],.listing-used-car-search [type=month],
        .listing-used-car-search [type=number],.listing-used-car-search [type=password],.listing-used-car-search [type=search],
        .listing-used-car-search [type=tel],.listing-used-car-search [type=text],.listing-used-car-search [type=time],
        .listing-used-car-search [type=url],.listing-used-car-search [type=week],.listing-used-car-search textarea{
            border: 1px solid #ccc !important;
        }
        .vdp-used-banner .paymentCalculator__payment sup,
        .vdp-used-banner .paymentCalculator__interests--amount sup{
            vertical-align: middle;
        }
        .TransactionProgressBar--progression{
            background-color: #00adef;
        }
        .widget-garage .vehicle-wrapper-info .vehicle-transaction{
            color: #00adef;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#00adef 55%);
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #00adef;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button.selected,
        .listing-used-bodystyle.selected{
            border: 2px solid #00adef;
            color: #00adef;
            background: #fff;
        }
        .InputText .input-control{
            border: 1px solid #d1d1d1 !important;
        }
        .sr-paymentCalculator-Panelbackground {
            background-color: #000000 !important;
        }
        .calculatorColumn,
        .calculator-done{
            border-right: 1px solid #666666 !important;
        }

        .InputNumber input{
            border: 1px solid #777 !important;
        }
        .showroom-vdp-used-desc .car-desc{
            word-break: break-word;
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #00adef!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#00adef 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #00adef !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #00adef !important;
            .icon,.icon-Crochet{
                color: #00adef !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #00adef;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #00adef;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #00adef !important;
            }
        }
        .doneLabel {
            color: #00adef !important;
            .icon-Crochet{
                color: #00adef !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #00adef !important;
            }
        }
        .has-success-background{
            background-color: #00adef !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__mercedes-benz{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}